.colBg{
    background-color: lightgray;
    margin: 10px 10px;
}
#mainContent {
    min-height: 100px !important;
}
.rowBg {
    background-color: white;
    margin: 12px 1px;
    padding: 5px;
}
.ddstyle {
    font-size: 19px;
}
.performacerow{
    padding: 5px;
    background-color: rgba(135, 206, 250, 0.301);
}
.performancerow2{
    display: flex;
    justify-content: space-between !important;
    margin: 2px 10px;
    padding: 6px;
    font-size: 15px;
    font-weight: bold;
    color: rgba(0, 0, 255, 0.534);
}
.performancerow3 {
    display: flex;
    justify-content: space-between !important;
    margin: 0px 10px;
    padding-left: 6px;
    padding-right: 6px;
}
.performancerow4{
    display: flex;
    justify-content: center !important;
    margin: 12px 10px;
}
.notificationrow{
    margin: 0px 10px;
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 10px;
}
.notificationrow1{
    margin: 2px 10px;
    padding: 10px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.404);
    border-radius: 10px;
}
#doubleline {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    margin-top: 12px;
}
#singleline {
    border-bottom: 1px solid grey;
    padding-bottom: 8px;
}
.tableitem{
    color: black;
    font-weight: normal;
    font-size: 14px;
}
.tableitembold{
    color: black;
    font-weight: bold;
    font-size: 14px;
}
.sideNavselling {
    display: flex;
    flex-direction: row;
}
.inputbord {
    border-color: black;
}
.headlines {
    border-top: 1px solid grey;
}
.wizardTitle {
    font-size: 16px !important;
}
.explainBlock {
    background-color: rgba(173, 216, 230, 0.431) !important;
}
.expTopbar {
    margin-right: 5px;
    margin-bottom: -15px;
}
.expTopbar2 {
    margin-right: 5px;
    margin-bottom: 5px;
}
.explainBlockdet {
    background-color: rgba(173, 216, 230, 0.431) !important;
    padding: 10px !important;
}
.onlineNav {
    padding-left: 0px !important;
    padding-right: 5px !important;
}
.onlineNav1 {
    display: flex;
    justify-content: flex-end;
}