 .hoverStyle i {
    /* position:absolute;
    left: 60px;
    top: 0px; */
    /* opacity: 0; */
    display: none;



}

.article-image:hover > i{
    /* /* color: black; */
    /* background-color: white; */
   /* width: 200px; */
    /* opacity: 1; */
    display: block;
    border: 1px solid black;

    /* position: absolute; */
    /* top: 0; */
    /* display: block; */
    /* color: #ffffff; */
    /* font-size: 16px;
    font-weight: bold;
    z-index: 0; */
    /* width: 100%; */
    /* height: 100%; */
    /* text-align: center;
    transition: .4s ease-in-out; */
    /* line-height:150px; */
    /* margin:0; */
}

/* .article-image{
    height: 150px;
    width: 238px;
    -webkit-filter: grayscale(0) blur(0);
    filter: grayscale(0) blur(0);
    transition: .4s ease-in-out;
} */


  /* .article-image:hover {
    -webkit-filter:  blur(5px);
    filter:  blur(2px);
    transition: .4s ease-in-out;
  } */
